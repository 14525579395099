import { BASE_ROUTES } from "../const";
import { Atom, Mod } from ":mods";
import { NotFoundWithRedirect } from "../components";
import { Landing as LandingLayout } from "../../layouts";
import { SignupViewProps } from "../model";
import { actions, hooks } from "../store";

export function Microsite() {
  const $: SignupViewProps["$"] = {
    actions: {
      ...actions,
      ...Mod.Account.actions,
    },
    hooks: {
      ...hooks,
    },
  };
  return (
    <Atom.Route.Guarded
      base={BASE_ROUTES.microsite}
      errorElement={() => (
        <NotFoundWithRedirect path={Mod.Microsite.CONST.ROUTES.home} base={BASE_ROUTES.microsite} duration={3000} />
      )}
      layout={() => (
        <LandingLayout
          topNav={{
            logoHref: BASE_ROUTES.microsite,
            class: "nav-top font-bold",
            base: BASE_ROUTES.microsite,
            items: [Mod.Microsite.Nav.TopAnchores, Mod.I18n.Nav.TopSelector, Mod.Microsite.Nav.TopExtras],
          }}
        />
      )}
      // guard={{
      //   title: "Mueseum Training",
      //   steps: undefined,
      // }}
      events={{
        onRouteFirstLoad: ({ location }) => {
          // console.log("first load microsite , ", location);
        },
        onRouteCleanup: ({ location }) => {
          // console.log("cleaning microsite data , ", location, " :: ", prevLocation);
        },
      }}
    >
      {() => (
        <>
          <Mod.Microsite.MicrositeRoutes
            $={$}
            signupLinks={{
              student: BASE_ROUTES.auth + Mod.Account.CONST.ROUTES.auth.signup + "?type=student",
              tutor: BASE_ROUTES.auth + Mod.Account.CONST.ROUTES.auth.signup + "?type=tutor",
            }}
          />
        </>
      )}
    </Atom.Route.Guarded>
  );
}
